<script>
  import { AbstractBaseRadioGroup } from '@/components/base/BaseRadioGroup'

  /**
   * DEPRECATED. Do not use and delete when there are no references to this component left. An unstyled group of `BaseButtonToggle` components, making them behave as radio buttons (where only one can be checked at a time).
   */
  export default {
    extends: AbstractBaseRadioGroup,
    provide() {
      return {
        BaseButtonToggleGroup: this,
      }
    },
  }
</script>

<template>
  <div>
    <!-- @slot The group’s content. Should contain at least two `BaseButtonToggle` components (not necessarily direct children). -->
    <slot />
  </div>
</template>
